.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: fit-content;
    background-color: #0f2e5d;
    overflow: hidden;
    color: white;
    font-family: Futura;
}

.mainImageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
    position: relative;
}

.bodyContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.gridContainer {
    margin-top: 5vh;
    margin-bottom: 5vh;
    width: 90vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2.5vw;
}

.gridElement {
    border: 1px solid white;
    width: 20vw;
    height: 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.gridElement img {
    width: 80%;
    object-fit: cover; /* Cover the area without breaking aspect ratio */
}

.header {
    margin-top: 5vh;
    margin-bottom: 2vh;
    width: 90vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.headerLogoAletti {
    width: 20vw;
}

.headerLogoBa {
    width: 7vw;
}

.slogan {
    position:absolute;
    color:white;
    bottom:5vh;
    font-size: 4vw;
}

@media only screen and (max-width: 600px) {
    .slogan {
        font-size: 4.5vw;
        bottom:0;
    }

    .headerLogoAletti {
        width: 40vw;
    }

    .headerLogoBa {
        width: 16vw;
    }
}