.mainContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.content {
    position: relative;
    width: 80%;
    max-width: 600px;
    background-color: #0f2e5d;
    padding: 20px;
    border-radius: 10px;
}

.iframeContainer {
    position: relative;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.closeButton {
    margin-left: auto;
    border: none;
    background-color: transparent;
    cursor: pointer !important;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    border-bottom: 1px solid white;
    margin-bottom: 1vh;
    margin-top: 0;
}